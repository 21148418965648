import React, { useState } from "react";
import "./text-input.scss";
import cn from "classnames";
import PropTypes from "prop-types";

const TextInput = props => {
	const {
		name,
		label,
		value,
		onChange,
		regexp,
		textError,
		autoComplete,
		required
	} = props;
	const [isValid, setIsValid] = useState(undefined);
	const [isFocused, setIsFocused] = useState(false);
	const [isFilled, setIsFilled] = useState(false);

	const handleChangeInput = e => {
		let isValid = true;
		if (required && !e.target.value) {
			isValid = false;
		}
		if (regexp) {
			isValid = new RegExp(regexp).test(e.target.value);
		}
		setIsValid(isValid);
		onChange(e, isValid);
	};

	const handleBlurInput = () => {
		if (value === "") {
			setIsFocused(false);
			setIsFilled(false);
		} else {
			setIsFocused(false);
			setIsFilled(true);
		}
	};

	const handleFocusInput = () => {
		setIsFocused(true);
	};

	return (
		<div
			className={cn(
				"text-input",
				{ error: isValid === false && isFilled },
				{ focused: isFocused },
				{ filled: isFilled }
			)}
		>
			<label htmlFor={name}>{required ? `${label}*` : label}</label>
			<input
				id={name}
				name={name}
				type="text"
				value={value}
				onChange={handleChangeInput}
				onFocus={handleFocusInput}
				onBlur={handleBlurInput}
				autoComplete={autoComplete}
			/>
			{isValid === false && isFilled && (
				<div className="text-input__error-block">{textError}</div>
			)}
		</div>
	);
};

TextInput.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	regexp: PropTypes.string,
	textError: PropTypes.string,
	autoComplete: PropTypes.string,
	required: PropTypes.bool
};

TextInput.defaultProps = {
	name: "input",
	label: "Введите значение",
	value: "",
	onChange: () => undefined,
	regexp: undefined,
	textError: "Недопустимое значение",
	autoComplete: "off",
	required: false
};

export default TextInput;
