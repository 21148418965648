import React, { useState } from "react";
import "./text-area.scss";

const TextArea = ({ name, label, value, onChange, required }) => {
	const [isFocused, setIsFocused] = useState(false);
	return (
		<div className={`text-area ${isFocused ? "focused" : ""}`}>
			<textarea
				id={name}
				name={name}
				value={value}
				onChange={onChange}
				placeholder={required ? `${label}*` : label}
				onFocus={() => setIsFocused(true)}
				onBlur={() => {
					if (value === "") {
						setIsFocused(false);
					}
				}}
			/>
		</div>
	);
};

export default TextArea;
